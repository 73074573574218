import React from "react"
import styled from "styled-components/macro"
import Providers from "./Providers"
import { createGlobalStyle } from "styled-components/macro"

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
  }
  body {
    background: ${props => props.theme.palette.background.default};
  }
`

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`

const Auth = ({ children }) => {
  return (
    <Providers>
      <GlobalStyle />
      <Root>{children}</Root>
    </Providers>
  )
}

export default Auth
